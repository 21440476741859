/* Base Styles -------------------- */

.top-pos-margin{
  margin-top: 50px;
}

.top-neg-margin{
  margin-top: -100px;
}

.bottom-neg-margin{
  margin-bottom: -100px;
}

.white-part{
  background: #fff;
  transform: translateZ(100px);
}

.blueish-part{
  background: $blueish;
  transform: translateZ(100px);
}

.blue-section{
  background: $blue;
  padding-top: 150px;
  padding-bottom: 150px;
  h3{
    color: #fff;
    margin-bottom: 20px;
  }
}

.white-section a{
  color: $pink;
  font-weight: bold;
}


html, body{
  color: $blue;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body{
  overflow-x: hidden;
  margin-top: 50px;
}

.wrapper{
  overflow: hidden;
}

@media (min-width: 768px) {
  .lg-padding{
    margin-top: 55px;
  }
  .bigger-margin{
    margin-top: 0px;
  }
}

@media (min-width: 768px) and (max-width: 992px){
  .margin-lg{
    margin-top: 188px;
  }
}

@media (min-width: 992px) {
  .bigger-margin{
    margin-top:-40px;
  }
  .margin-lg{
    margin-top: 138px;
  }
}


.inner-section{
  padding-right: 100px;
  padding-left: 100px;
  padding-top: 50px;
  padding-bottom: 50px;
  @media (max-width: 768px) {
    padding-left: 15px;
    padding-right: 15px;
  }

}

.slogan{
  font-family: 'cheddar_jackregular';
  font-size: 140px;
  line-height: 100px;
  text-align: center;
  display: inline-block;
  color: $blue;
  white-space: nowrap;
  margin-top: 30px;
  margin-bottom: 30px;
  font-weight: 400;
  position: absolute;
  left: 50%;
  transform: rotate(-5deg) translateX(-52%);
  @media (max-width: 470px) {
    transform: rotate(-5deg) translateX(-52%) scale(0.7);
  }
  @media (max-width: 350px) {
    transform: rotate(-5deg) translateX(-52%) scale(0.5);
  }
}

.children-icon{
  margin-top: 30px;
}

.born-title{
  font-size: 24px;
  font-weight: 700;
  display: block;
  margin-top: 20px;
  color: $blue;
  line-height: 24px;
}

.born-number{
  font-size: 150px;
  font-family: 'cheddar_jackregular';
  display: inline-block;
  line-height: 100px;
  margin-top: 30px;
}

.mini-slogan{
  font-size: 70px;
  line-height: 50px;
  font-family: 'cheddar_jackregular';
  display: inline-block;
  margin-bottom: 20px;
  margin-top:20px;
}

h3{
  font-size: 1rem;
  margin-bottom: 15px;
  font-weight: 700;
}

p{
  text-align: justify;
}

img{
  display: inline-block;
  margin-top: 15px;
  margin-bottom: 15px;
}

.left-col{
  .mini-slogan{
    @media (max-width: 768px) {
      margin-left: 0px !important;
    }

  }
  img{
    margin-left: -50px;
    @media (max-width: 768px) {
      margin-left: 0px;
    }

  }
}
.right-col{
  img{
    margin-right: -50px;
    @media (max-width: 768px) {
      margin-right: 0px;
    }
  }
}

.next-site-button{
  background: $pink;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  border: 1px solid $pink;
  -webkit-border-radius:0px;
  -moz-border-radius:0px;
  border-radius:0px;
  &:hover{
    background: lighten($pink, 5%);
    border: 1px solid;
    border-color: darken($pink, 5%);
  }
}

.big-number{
  color: $blueish;
  font-family: 'cheddar_jackregular';
  font-size: 160px;
  line-height: 100px;
  margin-top: 50px;
  margin-bottom: 10px;
  display: inline-block;
}

.bold-text{
  font-weight: 700;
  font-size: 20px;
  line-height: 20px;
}

.footer{
  font-weight: 700;
  padding-top: 25px;
  padding-bottom: 25px;
  a{
    color: $blue;
    text-decoration: none;
  }
}

.map-cont{
  position: relative;
  margin-top: 50px;
}

.map-row{
  position: relative;
}

.social{
  position: absolute;
  top: 10px;
  right: 30px;
}

.number{
  position: absolute;
  font-size: 80px;
  font-family: 'cheddar_jackregular';
  transform: translate3d(-50%, -50%, 0);
  line-height: 80px;
  @media (max-width: 767px) {
    font-size: 40px;
  }
  &.big{
    font-size: 150px;
    color: #fff;
    top: 29%;
    left: 15%;
    @media (max-width: 992px) {
      top: 30%;
    }
    @media (max-width: 768px) {
      top: 40%;
      font-size: 100px;
    }
  }
}

.choose{
  p{
    color: #fff;
  }
  .choose-box{
    width: 140px;
    height: 34px;
    overflow: hidden;
    background: url("../img/caret-down.svg") no-repeat 95% #fff;
    background-size: 15px;
    float: left;
    margin-right: 20px;
    @media (max-width: 500px) {
      width: 40%;
    }
    select{
      padding: 10px 20px;
      background: transparent;
      width: 168px;
      padding: 5px;
      font-size: 16px;
      line-height: 1;
      border: 0;
      border-radius: 0;
      height: 34px;
      -webkit-appearance: none;
      color: #000;
    }
  }

}


.saar{
  top: 59%;
  left: 12%;
}

.hiiu{
  top: 38%;
  left: 13%;
}

.laane{
  top: 38%;
  left: 32%;
}

.harju{
  top: 19%;
  left: 50%;
}

.rapla{
  top: 36%;
  left: 45%;
}

.parnu{
  top: 56%;
  left: 43%;
}

.viljandi{
   top: 60%;
   left: 59%;
 }

.jarva{
  top: 37%;
  left: 60%;
}

.laane-viru{
  top: 22%;
  left: 71%;
}

.ida-viru{
  top: 24%;
  left: 87%;
}

.jogeva{
  top: 46%;
  left: 73%;
}

.tartu{
  top: 61%;
  left: 76%;
}

.polva{
  top: 75%;
  left: 82%;
}

.voru{
  top: 88%;
  left: 80%;
}

.valga{
  top: 79%;
  left: 68%;
}

.prev-btn, .next-btn{
  cursor: pointer;
}

.checks{
  font-size: 20px;
  font-weight: 900;
  color: #fff;
  .row{
    margin-bottom: 20px;
  }
  .round {
    position: relative;
  }

  .round label {
    background-color: #1e345d;
    border: 5px solid #fff;
    border-radius: 50%;
    cursor: pointer;
    height: 30px;
    left: 0;
    position: absolute;
    top: 0;
    width: 30px;
  }


  .round input[type="checkbox"] {
    visibility: hidden;
  }

  .round input[type="checkbox"]:checked + label {
    background-color: $pink;
    border-color: #fff;
  }

  .round.green input[type="checkbox"]:checked + label {
    background-color: $blueish;
    border-color: #fff;
  }

  .round.gray input[type="checkbox"]:checked + label {
    background-color: #e9ecc7;
    border-color: #fff;
  }

  .round.light-blue input[type="checkbox"]:checked + label {
    background-color: #daf5ef;
    border-color: #fff;
  }

  .round.white input[type="checkbox"]:checked + label {
    background-color: #fff;
    border-color: #fff;
  }

  .round input[type="checkbox"]:checked + label:after {
    opacity: 1;
  }
}

.star{
  position: absolute;
  top: -93px;
  left: 102px;
  animation-duration: 20s;
  animation-delay: 5s;
}
.white-line{
  position: absolute;
  top: 33px;
  left: -30px;
  animation-duration: 30s;
  animation-delay: 7s;
}
.pink-line{
  position: absolute;
  top: 169px;
  left: 90px;
  animation-duration: 35s;
  animation-delay: 10s;
}
.white-lines{
  position: absolute;
  top: 31px;
  right: -48px;
  animation-duration: 15s;
  animation-delay: 6s;
}

.slogan-text{
  margin-top: 300px;
}

.bg-image{
  position: absolute;
}

.top-icon{
  animation-name: wiggle;
  animation-iteration-count: infinite;
}

.padding-5{
  padding: 5px 0px
}

.main-link{
  color: $pink;
  font-weight: 700;
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes wiggle {
  0%   {transform: rotate(0deg)}
  51%   {transform: rotate(0deg)}
  52%  {transform: rotate(10deg)}
  53%  {transform: rotate(-10deg)}
  54%  {transform: rotate(10deg)}
  55%  {transform: rotate(-10deg)}
  56%  {transform: rotate(0deg)}
  100% {transform: rotate(0deg)}
}

/* Standard syntax */
@keyframes wiggle {
  0%   {transform: rotate(0deg)}
  51%   {transform: rotate(0deg)}
  52%  {transform: rotate(10deg)}
  53%  {transform: rotate(-10deg)}
  54%  {transform: rotate(10deg)}
  55%  {transform: rotate(-10deg)}
  56%  {transform: rotate(0deg)}
  100% {transform: rotate(0deg)}
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes wigglefast {
  0%   {transform: rotate(0deg) translate3d(-50%, -50%, 0) scale(1)}
  30%   {transform: rotate(0deg) translate3d(-50%, -50%, 0) scale(1)}
  40%  {transform: rotate(10deg) translate3d(-50%, -50%, 0) scale(1.1)}
  50%  {transform: rotate(-10deg) translate3d(-50%, -50%, 0) scale(1.1)}
  60%  {transform: rotate(10deg) translate3d(-50%, -50%, 0) scale(1.1)}
  70%  {transform: rotate(-10deg) translate3d(-50%, -50%, 0) scale(1.1)}
  80%  {transform: rotate(0deg) translate3d(-50%, -50%, 0) scale(1)}
  100% {transform: rotate(0deg) translate3d(-50%, -50%, 0) scale(1)}
}

/* Standard syntax */
@keyframes wigglefast {
  0%   {transform: rotate(0deg) translate3d(-50%, -50%, 0) scale(1)}
  30%   {transform: rotate(0deg) translate3d(-50%, -50%, 0) scale(1)}
  40%  {transform: rotate(10deg) translate3d(-50%, -50%, 0) scale(1.1)}
  50%  {transform: rotate(-10deg) translate3d(-50%, -50%, 0) scale(1.1)}
  60%  {transform: rotate(10deg) translate3d(-50%, -50%, 0) scale(1.1)}
  70%  {transform: rotate(-10deg) translate3d(-50%, -50%, 0) scale(1.1)}
  80%  {transform: rotate(0deg) translate3d(-50%, -50%, 0) scale(1)}
  100% {transform: rotate(0deg) translate3d(-50%, -50%, 0) scale(1)}
}

img.play-button {
  width: 30px !important;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: rotate(0deg) translate3d(-50%, -50%, 0) scale(1)
}

.item:hover .play-button{
  animation-name: wigglefast;
  animation-iteration-count: infinite;
  animation-duration: 2s;
  transform-origin: -20% -15%;
}

span.t {
  display: inline-block;
  margin-right: -16px;
}
header, section, footer, aside, nav, main, article, figure {
  display: block;
}