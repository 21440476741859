//Custom variables

// Descriptive color variables

// Functional color variables

$blueish :  #9be6d7;
$blue:      #1e345d;
$pink:      #ffaeba;


// Font stacks

// Asset paths
$path-image   :   '../img';
$path-fonts     :  '../fonts';

