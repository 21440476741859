// Bootstrap Grid only
//
// Includes relevant variables and mixins for the flexbox grid
// system, as well as the generated predefined classes (e.g., `.col-sm-4`).

//
// Box sizing, responsive, and more
//

@at-root {
  @-ms-viewport { width: device-width; }
}

html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

@import "source/functions";
@import "source/variables";

//
// Grid mixins
//

@import "source/mixins/breakpoints";
@import "source/mixins/grid-framework";
@import "source/mixins/grid";

@import "source/grid";
@import "source/utilities/flex";
